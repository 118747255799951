// eslint-disable-next-line no-restricted-imports

/* eslint-disable no-restricted-imports */
import { timeDay, timeHour } from 'd3-time';
import moment, { DurationInputArg1, type unitOfTime } from 'moment';
import momentTZ from 'moment-timezone';

import { isNil, sortArrayOfObjectsByProp } from '@shared/utils';

import {
  DATE_FORMAT,
  DATE_TIME_SECONDS_FORMAT,
  EPOCH_TIME,
  ISO_FORMAT,
  LOCALE_DAY_NAME_SHORT_FORMAT,
} from './constants';
import { DateTime, TDateFormatMap, TIsoFormat } from './types';

export type DateTimeInput = DateTime | Date | string | number | number[] | null;
type DateTimeRangeInput = [string | number | null, string | number | null];

export const momentToDate = (momentObject: DateTime) => momentObject.toDate();

export const dateTimeToDate = (date: DateTime): Date => {
  return new Date(date.format('YYYY/MM/DD'));
};

export const dateTimeToDateWithTime = (date: DateTime): Date => {
  return new Date(date.format(DATE_TIME_SECONDS_FORMAT));
};

export const isDateTimeObject = (value: any): value is DateTime => {
  return moment.isMoment(value);
};

export const newDate = (date?: DateTimeInput | null): DateTime =>
  date ? moment(date) : moment();

export const format = (date?: DateTimeInput, dateFormat?: string) =>
  dateFormat ? newDate(date).format(dateFormat) : newDate(date).format();

export const difference = (
  firstDate: DateTimeInput,
  secondDate: DateTimeInput,
  unit: unitOfTime.Diff,
  precise = false,
) => newDate(firstDate).diff(newDate(secondDate), unit, precise);

export function dayOfWeek(date: DateTimeInput, dayValue?: undefined): number;
export function dayOfWeek(
  date: DateTimeInput,
  dayValue?: number | string,
): DateTime;
export function dayOfWeek(date: DateTimeInput, dayValue?: number | string) {
  return isNil(dayValue) ? newDate(date).day() : newDate(date).day(dayValue);
}

export const getWeekdaysSummary = (
  weekdays: number[],
  {
    dayFormat = LOCALE_DAY_NAME_SHORT_FORMAT,
    firstDayOfWeek = weekdays[0] || 0,
  } = {},
) => {
  const parts: string[] = [];
  const bracket: number[] = [];

  const getDayName = (weekday?: number) => {
    return format(dayOfWeek(new Date(), weekday), dayFormat);
  };

  const pickedDays = new Set(weekdays);

  const allWeekdays = new Array(7)
    .fill(0)
    .map((_, i) => (i + firstDayOfWeek) % 7);

  allWeekdays.forEach((day, i) => {
    if (!pickedDays.has(day)) return;
    bracket[isNil(bracket[0]) ? 0 : 1] = day;
    if (!pickedDays.has((day + 1) % 7) || i === 6) {
      parts.push(bracket.map(getDayName).join('-'));
      bracket.length = 0;
    }
  });
  return parts.join(', ');
};

export const constructDate = (
  config: { year?: number; week?: number; day?: number },
  base?: DateTimeInput,
) => {
  const date = newDate(base);
  if (config.year) date.year(config.year);
  if (config.week) date.week(config.week);
  if (config.day) date.day(config.day);

  return date;
};

type ValidateDateFormat<T extends string, F extends keyof TDateFormatMap> = [
  T,
] extends [TDateFormatMap[F]]
  ? [TDateFormatMap[F]] extends [T]
    ? T
    : never
  : never;

export const stringToDate = <T extends string, F extends keyof TDateFormatMap>(
  date?: ValidateDateFormat<T, F>,
  dateFormat?: F,
): DateTime => (date ? moment(date, dateFormat) : moment());

export const isValidDate = (date: DateTime) =>
  moment.isMoment(date) ? date.isValid() : moment(date).isValid();

export const isSameOrAfter = (
  date: DateTimeInput,
  start: DateTimeInput,
  unit?: unitOfTime.StartOf,
) => newDate(date).isSameOrAfter(start, unit);

export const isSameOrBefore = (
  date: DateTimeInput,
  start: DateTimeInput,
  unit: unitOfTime.StartOf = null,
) => newDate(date).isSameOrBefore(start, unit);

export const isBetween = (
  date: DateTimeInput,
  start: DateTimeInput,
  end: DateTimeInput,
  unit: unitOfTime.StartOf,
) => newDate(date).isBetween(start, end, unit);

// Inclusivity works by passing ( to exclude and [ to include, so for example if you want to include both start and end you send []
export const isBetweenWithInclusivity = (
  date: DateTimeInput,
  start: DateTimeInput,
  end: DateTimeInput,
  unit: unitOfTime.StartOf,
  inclusivity: '()' | '[)' | '(]' | '[]',
) => newDate(date).isBetween(start, end, unit, inclusivity);

export const isSame = (
  firstDate?: DateTimeInput,
  secondDate?: DateTimeInput,
  unit?: unitOfTime.StartOf,
) => newDate(firstDate).isSame(secondDate, unit);

export const duration = (time: DurationInputArg1) => moment.duration(time);

export const formatToISO = (date: DateTimeInput) =>
  format(date, ISO_FORMAT) as TIsoFormat;

export const formatToDate = (date: DateTimeInput) => format(date, DATE_FORMAT);

export const isBefore = (
  date: DateTimeInput,
  dateAfter?: DateTimeInput,
  unit?: unitOfTime.StartOf,
) => newDate(date).isBefore(dateAfter, unit);

export const isAfter = (
  date: DateTimeInput,
  dateBefore: DateTimeInput,
  unit?: unitOfTime.StartOf,
) => newDate(date).isAfter(dateBefore, unit);

export const getMaxDate = (datesArray: DateTime[]) => moment.max(datesArray);

export const arePeriodsOverlapping = (
  [aStart, aEnd]: DateTimeRangeInput,
  [bStart, bEnd]: DateTimeRangeInput,
  inclusive = false,
) => {
  if (inclusive) return !isAfter(bStart, aEnd) && !isBefore(bEnd, aStart);
  return isBefore(bStart, aEnd) && isAfter(bEnd, aStart);
};

export const weekday = (date: DateTimeInput) => newDate(date).weekday();

export const week = (date: DateTimeInput) => newDate(date).week();

export const isoWeek = (date: DateTimeInput) => newDate(date).isoWeek();

export const subtractUnits = (
  date: DateTimeInput,
  amount: DurationInputArg1,
  unit: unitOfTime.DurationConstructor | undefined,
) => newDate(date).subtract(amount, unit);

export const subtractSeconds = (
  date: DateTimeInput,
  value: DurationInputArg1,
) => newDate(date).subtract(value, 'seconds');

export const subtractMinutes = (
  date: DateTimeInput,
  value: DurationInputArg1,
) => newDate(date).subtract(value, 'minutes');

export const subtractHours = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).subtract(value, 'hours');

export const subtractDays = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).subtract(value, 'days');

export const subtractWeeks = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).subtract(value, 'weeks');

export const subtractMonths = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).subtract(value, 'months');

export const subtractYears = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).subtract(value, 'years');

export const addUnits = (
  date: DateTimeInput,
  amount: DurationInputArg1,
  unit: unitOfTime.DurationConstructor | undefined,
) => newDate(date).add(amount, unit);

export const addMinutes = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).add(value, 'minutes');

export const addHours = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).add(value, 'hours');

export const addDays = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).add(value, 'days');

export const addWeeks = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).add(value, 'weeks');

export const addMonths = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).add(value, 'months');

export const addYears = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).add(value, 'years');

export const addMilliseconds = (
  date: DateTimeInput,
  value: DurationInputArg1,
) => newDate(date).add(value, 'milliseconds');

export const addDuration = (date: DateTimeInput, value: DurationInputArg1) =>
  newDate(date).add(value);

export const subtractDuration = (
  date: DateTimeInput,
  value: DurationInputArg1,
) => newDate(date).subtract(value);

export const fromNow = (date: DateTimeInput) => newDate(date).fromNow();

/**
 * TODO - newDate doesn't take a second parameter. Evaluate this!
 * @param date
 * @param timeZone
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fromNowTZ = (date: DateTimeInput, timeZone: string) => {
  // return newDate(date, timeZone).fromNow();
  return newDate(date).fromNow();
};

export const maximum = (dates: DateTime[]) => moment.max(dates);

export const minimum = (dates: DateTime[]) => moment.min(dates);

export const startOf = (date: DateTimeInput, value: unitOfTime.StartOf) =>
  newDate(date).startOf(value);

export const endOf = (date: DateTimeInput, value: unitOfTime.StartOf) =>
  newDate(date).endOf(value);

export const minutes = (date: DateTimeInput) => newDate(date).minutes();

export const setMinutes = (date: DateTimeInput, m: number | null | undefined) =>
  !isNil(m) ? newDate(date).minutes(m) : newDate(date);

export const hours = (date: DateTimeInput) => newDate(date).hours();

export const setHours = (date: DateTimeInput, h: number | null | undefined) =>
  !isNil(h) ? newDate(date).hours(h) : newDate(date);

export const dayOfMonth = (date: DateTimeInput) => newDate(date).date();

export const month = (date: DateTimeInput) => newDate(date).month();

export const setDate = (date: DateTimeInput, d: number) =>
  !isNil(d) ? newDate(date).date(d) : newDate(date);

export const setMonth = (date: DateTimeInput, m: string | number) =>
  !isNil(m) ? newDate(date).month(m) : newDate(date);

export const year = (date?: DateTimeInput) => newDate(date).year();

export const setYear = (date: DateTimeInput, y: number) =>
  !isNil(y) ? newDate(date).year(y) : newDate(date);

export const isLeapYear = (date: DateTimeInput) => newDate(date).isLeapYear();

export const monthsList = () => moment.months();

export const asHours = (milliseconds: DurationInputArg1) =>
  moment.duration(milliseconds).asHours();

export const asMinutes = (milliseconds: DurationInputArg1) =>
  moment.duration(milliseconds).asMinutes();

export const asMilliseconds = (
  time: DurationInputArg1,
  unit: unitOfTime.DurationConstructor | undefined,
) => moment.duration(time, unit).asMilliseconds();

export const inAMonth = (start: DateTimeInput, end: DateTimeInput) =>
  difference(end, start, 'months') < 1 && difference(end, start, 'months') > -1;

export const inAMonthAhead = (start: DateTimeInput, end: DateTimeInput) =>
  difference(end, start, 'days') < 31 && difference(end, start, 'days') > -1;

export const inDaysAhead = (
  start: DateTimeInput,
  end: DateTimeInput,
  value: number,
) =>
  difference(end, start, 'days') < value && difference(end, start, 'days') > -1;

export const locale = () => moment.locale();

export const firstDayOfWeek = () => moment.localeData().firstDayOfWeek();

export const daysInMonth = (m: string | number) =>
  newDate().month(m).daysInMonth();

export const isSunday = (date: DateTimeInput) => dayOfWeek(date) === 0;

export const clone = (date: DateTimeInput): DateTime =>
  !isNil(date) ? newDate(date).clone() : newDate().clone();

// Globally mutating

export const globalUpdateLocale = (newLocale?: string | undefined) =>
  moment.locale(newLocale);

export const globalUpdateFirstDayOfWeek = (fdow: number) =>
  moment.updateLocale(moment.locale(), {
    week: { dow: fdow },
  });

// With time zones

export const timeZoneNames = () => momentTZ.tz.names();

export const currentDateWithTZ = (timeZone: string) =>
  momentTZ.tz(momentTZ.tz(timeZone).format(ISO_FORMAT), timeZone);

export const newDateWithTZ = (timeZone: string, date: DateTime | string) =>
  date ? momentTZ.tz(date, timeZone) : momentTZ.tz(timeZone);

export const addDurationWithTZ = (
  timeZone: string,
  date: DateTime,
  value: DurationInputArg1,
) => newDateWithTZ(timeZone, date).add(value);

export const addDaysWithTz = (
  timeZone: string,
  date: DateTime | string,
  value: DurationInputArg1,
) => newDateWithTZ(timeZone, date).add(value, 'days');

export const addHoursWithTz = (
  timeZone: string,
  date: DateTime,
  value: DurationInputArg1,
) => newDateWithTZ(timeZone, date).add(value, 'hours');

export const subtractHoursWithTz = (
  timeZone: string,
  date: DateTime,
  value: DurationInputArg1,
) => newDateWithTZ(timeZone, date).subtract(value, 'hours');

export const addMinutesWithTz = (
  timeZone: string,
  date: DateTime | string,
  value: DurationInputArg1,
) => newDateWithTZ(timeZone, date).add(value, 'minutes');

export const subtractMinutesWithTz = (
  timeZone: string,
  date: DateTime | string,
  value: DurationInputArg1,
) => newDateWithTZ(timeZone, date).subtract(value, 'minutes');

export const addMilisecondsWithTz = (
  timeZone: string,
  date: DateTime,
  value: DurationInputArg1,
) => newDateWithTZ(timeZone, date).add(value, 'milliseconds');

export const setHoursWithTZ = (timeZone: string, date: DateTime, h: number) =>
  !isNil(h)
    ? newDateWithTZ(timeZone, date).hours(h)
    : newDateWithTZ(timeZone, date);

export const startOfWithTZ = (
  date: DateTime,
  value: unitOfTime.StartOf,
  timeZone: string,
) => newDateWithTZ(timeZone, date).startOf(value);

export const endOfWithTZ = (
  date: DateTime,
  value: unitOfTime.StartOf,
  timeZone: string,
) => newDateWithTZ(timeZone, date).endOf(value);

export const getUserTimeZone = () => momentTZ.tz.guess();

// Globally mutating with time zones

export const globalUpdateDefaultTz = (timeZone: string) =>
  momentTZ.tz.setDefault(timeZone);

export const createLockPeriod = (lockDate: DateTimeInput) => ({
  begin: EPOCH_TIME,
  end: lockDate
    ? format(addDays(subtractSeconds(newDate(lockDate), 1), 1), ISO_FORMAT)
    : EPOCH_TIME,
});

export const removeTZ = (date: DateTimeInput) =>
  newDate(format(date, ISO_FORMAT));

export const isDayBreakToDayBreak = (
  start: DateTimeInput,
  end: DateTimeInput,
  dayBreak: string,
) =>
  format(start, 'HH:mm:00') === dayBreak &&
  format(end, 'HH:mm:00') === dayBreak;

export const isDaylightSavingTime = (date: DateTime) => moment(date).isDST();

export const isTimeBefore = (fromTime: string, toTime: string) => {
  const fromDate = new Date(`1/1/2001 ${fromTime}`);
  let toDate = new Date(`1/1/2001 ${toTime}`);

  if (toTime === '00:00' || toTime === '00:00:00') {
    toDate = new Date(`1/1/2001 23:59:00`);
  }

  return isBefore(fromDate, toDate);
};

export const hasOverlappingPeriods = (
  list: Array<{ from: string; to: string }>,
) => {
  if (list.length <= 1) return false;

  const sortedList = sortArrayOfObjectsByProp(
    list.map(({ from, to }) => ({
      from: from.slice(0, 5),
      to: to.slice(0, 5),
    })),
    'from',
  );

  for (let i = 0; i < sortedList.length - 1; i += 1) {
    const current = sortedList[i];
    const next = sortedList[i + 1];

    if (!current || !next) throw new Error('Invalid item in list');

    if (isTimeBefore(next.from, current.to)) {
      return true;
    }
  }

  return false;
};

export const getHourlyRange = (
  startDate: DateTime,
  endDate: DateTime,
  dayBreak: DurationInputArg1,
): Date[] => {
  let start = startDate;
  let end = endDate;

  if (dayBreak) {
    const dayBreakDuration = duration(dayBreak).hours();

    start = setHours(startDate, dayBreakDuration);
    end = addDays(start, 1);
  }

  return timeHour.range(start.toDate(), end.toDate(), 1);
};

export const getDatesWithDayBreakTzAware = <T extends DateTime[]>(
  dates: T,
  timeZone: string,
  dayBreak: DurationInputArg1,
) => {
  const dayBreakAsHours = asHours(duration(dayBreak));
  return dates.map((date: DateTime) =>
    setHoursWithTZ(timeZone, date, dayBreakAsHours),
  ) as T;
};

export const getHourlyRangeWithTZ = (
  startDate: DateTime,
  endDate: DateTime,
  dayBreak: DurationInputArg1,
  timeZone: string,
): Date[] => {
  let start = startDate;
  let end = endDate;

  if (dayBreak) {
    [start, end] = getDatesWithDayBreakTzAware(
      [startDate, addDaysWithTz(timeZone, start, 1)] as const,
      timeZone,
      dayBreak,
    );
  }

  return timeHour.range(start.toDate(), end.toDate(), 1);
};

function unique<T>(items: T[]) {
  return Array.from(new Set(items));
}

export const createDateRange = (
  startDate: Date,
  endDate: Date,
): TIsoFormat[] => {
  const withDST = timeDay.range(startDate, endDate, 1);
  return unique(withDST.map((date) => date.toISOString())) as TIsoFormat[];
};

export const getLastYearSameDay = (date: DateTime | string) => {
  const dateTime = newDate(date);
  const isoWeekNum = dateTime.isoWeek();
  const isoDayNum = dateTime.isoWeekday();
  return dateTime.subtract(1, 'y').isoWeek(isoWeekNum).isoWeekday(isoDayNum);
};
