import { SORT_ORDER } from '@shared/constants';
import { TSortOrder } from '@shared/constants/types';

import { sortByTruthiness } from '@ManagerPortal/utils/schedule/sorting/utils';

import { createTypedObjectFromEntries } from './typeUtils';

export * from './index-old';
type PassedPropNameOrId<T extends string | undefined> = T extends string
  ? T
  : 'id';

export const indexArrayByPropName = <
  ArrayItem extends { [key in PassedPropNameOrId<PropName>]: string | number },
  PropName extends string | undefined = undefined,
>(
  array: ArrayItem[] = [],
  propName: PassedPropNameOrId<PropName> = 'id' as PassedPropNameOrId<PropName>,
) => createTypedObjectFromEntries(array.map((item) => [item[propName], item]));

export function isObject(item: unknown): item is { [key: string]: unknown } {
  return typeof item === 'object' && !Array.isArray(item) && item != null;
}

export const isNil = (value: unknown): value is null | undefined =>
  value === null || value === undefined;

export const isNotNil = <T>(value: T): value is NonNullable<T> => {
  return !isNil(value);
};

export const isEmpty = (value: unknown) => {
  if (isNil(value)) {
    return true;
  }
  if (typeof value === 'object' && value) {
    if (Object.keys(value).length === 0) {
      // for mmaps and sets
      if ('size' in value) return value.size === 0;
      // for plain objects and arrays
      return true;
    }
  }
  return false;
};

export const ensureValue = <T>(value: T | undefined | null) => {
  if (isNil(value)) throw new Error('Value is undefined or null');
  return value;
};

export const getUniqueEntriesFromList = <
  T extends Record<string | number | symbol, unknown>,
>(
  list: T[],
  get: string | ((value: unknown) => boolean),
) => {
  if (isNil(get)) return [...new Set(list)];

  const ids = new Set();
  const getId = typeof get === 'function' ? get : (item: T) => item[get];

  return list.filter((item) => {
    if (isNil(item)) return false;

    const id = getId(item);

    if (ids.has(id)) return false;

    ids.add(id);
    return true;
  });
};

type SortObject<K extends PropertyKey, KT extends PropertyKey, T> = Record<
  K,
  KT
> &
  T;

type SortAccessor<K extends PropertyKey> = K | SortFunction<K>;

type SortFunction<K extends PropertyKey> = (
  value: Record<K, unknown>,
) => string | number;

type ObjectArraySortingParameters<
  K extends PropertyKey,
  KT extends PropertyKey,
  T,
> = [SortObject<K, KT, T>[], SortAccessor<K>, TSortOrder?];

type ObjectArraySorthingByMultiplePropsParameters<
  K extends PropertyKey,
  KT extends PropertyKey,
  T,
> = [SortObject<K, KT, T>[], SortAccessor<K>[], TSortOrder?];

const getItemValue = <K extends PropertyKey, KT extends PropertyKey>(
  item: Record<K, KT>,
  accessor: K | SortFunction<K>,
) => {
  if (typeof accessor === 'function') {
    return accessor(item);
  }
  return item[accessor];
};

const getComparison =
  <K extends PropertyKey>(accessor: K | SortFunction<K>, sign: 1 | -1) =>
  (a: Record<K, PropertyKey>, b: Record<K, PropertyKey>) => {
    const aValue = getItemValue(a, accessor);
    const bValue = getItemValue(b, accessor);

    const aIsNil = isNil(aValue);
    const bIsNil = isNil(bValue);
    if (aIsNil || bIsNil) {
      return sortByTruthiness(aIsNil, bIsNil);
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue) * sign;
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return (aValue - bValue) * sign;
    }

    throw new Error('Trying to sort array by non-comparable values');
  };

export const sortArrayOfObjectsByProp = <
  K extends PropertyKey,
  KT extends PropertyKey,
  T,
>(
  ...[
    array,
    accessor,
    sortOrder = SORT_ORDER.ASC,
  ]: ObjectArraySortingParameters<K, KT, T>
) => {
  if (!array || !accessor) {
    return [];
  }
  const sign = sortOrder === SORT_ORDER.ASC ? 1 : -1;

  const compareFunction = getComparison(accessor, sign);
  return [...array].sort(compareFunction);
};

export const sortArrayOfObjectsByMultipleProps = <
  K extends PropertyKey,
  KT extends PropertyKey,
  T,
>(
  ...[
    array,
    accessors,
    sortOrder = SORT_ORDER.ASC,
  ]: ObjectArraySorthingByMultiplePropsParameters<K, KT, T>
) => {
  if (isEmpty(array)) {
    return [];
  }
  if (!accessors?.length) {
    return array;
  }
  const sign = sortOrder === SORT_ORDER.ASC ? 1 : -1;
  const compareFunctions = accessors.map((accessor) =>
    getComparison(accessor, sign),
  );

  return [...array].sort((a, b) => {
    let result = 0;
    compareFunctions.some((compareFunction) => {
      result = compareFunction(a, b);
      return result !== 0;
    });
    return result;
  });
};
