import { Matcher } from 'react-day-picker';

import { isNil } from '@shared/utils';
import {
  dateTimeToDate,
  format,
  isDateTimeObject,
  newDate,
  year,
} from '@shared/utils/time';
import { DateTime } from '@shared/utils/time/types';

type PossibleDateTypes = string | Date | DateTime | number | undefined;

const isValidJsDate = (d: Date): boolean => {
  return d instanceof Date && !Number.isNaN(d);
};

const getInitialValueUnconstrained = (
  value: PossibleDateTypes,
): Date | undefined => {
  if (isNil(value)) return undefined;
  if (isDateTimeObject(value)) return dateTimeToDate(value);

  switch (typeof value) {
    case 'string':
      return dateTimeToDate(newDate(value)); // this conversion ensures that new date is not changed due to different local time
    case 'number':
      return value === Infinity ? undefined : new Date(value);
    default:
      return value;
  }
};

const getInitialValue = (
  value: PossibleDateTypes,
  min: Date | undefined,
  max: Date | undefined,
  constrain = true,
): Date | undefined => {
  if (!value) {
    return undefined;
  }

  const date = getInitialValueUnconstrained(value);

  if (!date) {
    return undefined;
  }

  if (!constrain) {
    return date;
  }

  const minDate = !isNil(min) && isValidJsDate(min) ? min : undefined;
  const maxDate = !isNil(max) && isValidJsDate(max) ? max : undefined;

  const constainedDate =
    minDate && minDate > date
      ? minDate
      : maxDate && maxDate < date
      ? maxDate
      : date;

  return constainedDate;
};

const getInitialValueStr = (
  value: Date | undefined,
  displayFormat: string,
): string => {
  if (value) {
    const momentDate = newDate([
      value.getFullYear(),
      value.getMonth(),
      value.getDate(),
    ]);

    return format(momentDate, displayFormat);
  }

  return format(null, displayFormat);
};

const years = (start: number, futureYearsRange: number) =>
  Array.from(Array(year() - start + 1 + futureYearsRange).keys())
    .map((i) => i + start)
    .reverse();

export type TYearOptionsType = Array<{
  label: string;
  value: number;
}>;

const getYearOptions = (futureYearsRange: number): TYearOptionsType =>
  years(1940, futureYearsRange).map((val) => ({
    label: val.toString(),
    value: val,
  }));

const getMixMaxDates = (
  minDate: PossibleDateTypes,
  maxDate: PossibleDateTypes,
) => {
  const min = getInitialValueUnconstrained(minDate);
  const max = getInitialValueUnconstrained(maxDate);

  return { min, max };
};

const getDisabledMatcher = (min: Date | undefined, max: Date | undefined) => {
  const disabledMatcher: Array<Matcher> = [];

  if (max && isValidJsDate(max)) {
    disabledMatcher.push({ after: new Date(max) });
  }

  if (min && isValidJsDate(min)) {
    disabledMatcher.push({ before: new Date(min) });
  }

  return disabledMatcher;
};

export {
  getInitialValue,
  getInitialValueStr,
  getInitialValueUnconstrained,
  getYearOptions,
  getMixMaxDates,
  getDisabledMatcher,
  years,
};
