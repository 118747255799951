import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import classNames from 'classnames';

import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import LegacyDiv from '@shared/components/LegacyDiv';

import useTranslate from '../../hooks/useTranslate';
import { withForm } from '../Form';
import Label from '../Label';

import s from './index.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /** Passing this prop to a `<Input />` outside of `<Form/>` component will only do a basic html validation */
  required?: boolean;
  label?: string;
  info?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: never;
  /** prop valid only for inputs with calendar icons (types time, date etc.) */
  hideCalendarIcon?: boolean;
  error?: string;
  wrapperTestId?: string;
}

const InputComponent = (
  {
    label = '',
    info,
    error,
    placeholder,
    required,
    id = crypto.randomUUID(),
    className: _className,
    hideCalendarIcon = false,
    wrapperTestId,
    ...props
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const translate = useTranslate();

  return (
    <LegacyDiv className={s.inputWrap} data-test-id={wrapperTestId}>
      {label && <Label htmlFor={id} label={label} required={required} />}

      <LegacyInput
        {...props}
        ref={ref}
        id={id}
        className={classNames(s.input, hideCalendarIcon && s.input_noCalendar)}
        placeholder={placeholder ? translate(placeholder) : ''}
      />

      {error && <span className={s.errorMsg}>{translate(error)}</span>}

      {info && <span className={s.infoMsg}>{translate(info)}</span>}
    </LegacyDiv>
  );
};

export const Input = forwardRef(InputComponent);

export const FormInput = withForm(Input);
