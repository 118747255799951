import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import equal from 'fast-deep-equal';

import {
  transformApi,
  transformApiDefaultState,
  transformFetchApi,
} from '@shared/api/utils';
import { TupleToUnion } from '@shared/utils/typeUtils';

import {
  ApiError,
  ApiOptions,
  ApiState,
  CallParams,
  InitialApiState,
  SuperAgentApi,
  WrappedApi,
} from './types';

type SetReactApiState<T> = React.Dispatch<
  React.SetStateAction<ApiState<T> | InitialApiState<T>>
>;

const useWrapApi = <DataType>(
  setState: SetReactApiState<DataType>,
  transformApiFn: typeof transformFetchApi | typeof transformApi,
) => {
  const mounted = useRef(false);
  const apiAborts = useRef<{ (): void }[]>([]);

  const signal = useRef<string | null>(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      // This is expected to call aborts that were inserted into the array later
      // eslint-disable-next-line react-hooks/exhaustive-deps
      apiAborts.current.forEach((abort: () => void) => abort());
    };
  }, []);

  return useCallback(
    <Params extends CallParams>(api: SuperAgentApi<DataType, Params>) => {
      const transformedApi = transformApiFn(api, signal);

      return async (...params: Params) => {
        setState((state) => ({
          ...state,
          ...transformApiDefaultState,
          isAttempted: true,
          isLoading: true,
        }));

        const [calledApi, abort] = transformedApi(params);
        if (abort) apiAborts.current.push(abort);

        try {
          const result = await calledApi;
          if (mounted.current) {
            setState((state) => ({
              ...state,
              ...result,
            }));
          }
          return result;
        } catch (error) {
          if (mounted.current) {
            setState((state) => ({
              ...state,
              ...(typeof error === 'object' ? error : {}),
            }));
          }

          throw error;
        }
      };
    },
    [setState],
  );
};

const initialState = <T>(apiOptions?: ApiOptions<T>) => ({
  ...transformApiDefaultState,
  data: apiOptions?.initialData,
  invalidated: Date.now(),
});

const getOptions = <P extends CallParams, T>(
  arg1: ApiOptions<T> | SuperAgentApi<T, P>,
  // This gets called in tests without arg1, so despite the types that should never be undefined
  // they can be undefined in practice
) => (arg1 && 'initialData' in arg1 ? arg1 || undefined : undefined);

const useApiState = <T>(startState: InitialApiState<T>) => {
  const [state, setState] = useState<ApiState<T> | InitialApiState<T>>(
    startState,
  );

  // modifiers put to separate useMemo to achieve reference equality on state change
  const modifiers = useMemo(
    () => ({
      setData: (data: T) =>
        setState((prevState) => {
          if (equal(prevState.data, data)) return prevState;
          return { ...prevState, data };
        }),
      setErrors: (errors: ApiError) =>
        setState((prevState) => ({
          ...prevState,
          errors,
        })),
      reset: () =>
        setState({
          // TODO: What if we deal with an object created from ApiOptions?
          // shouldn't we call it with `startState().data`?
          ...initialState(),
          invalidated: Date.now(),
        }),
      invalidate: () =>
        setState((prevState) => ({
          ...initialState(),
          data: prevState.data,
          invalidated: Date.now(),
        })),
    }),
    [],
  );

  const stateAndModifiers = useMemo(
    () => ({
      ...state,
      ...modifiers,
    }),
    [modifiers, state],
  );

  return [stateAndModifiers, setState] as const;
};

type ApiArray<
  D,
  // We are going to infer the type of params;
  // using unknown[] breaks the inference
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends SuperAgentApi[] = SuperAgentApi<D, any[]>[],
> = TupleToUnion<T> extends SuperAgentApi<D, infer P>
  ? SuperAgentApi<D, P>[]
  : [];

type UseApiReturnRest<D, A extends ApiArray<D> | []> = A extends [
  SuperAgentApi<D, infer AP>,
  ...infer U,
]
  ? [
      WrappedApi<D, AP>,
      ...(U extends SuperAgentApi<D>[] ? UseApiReturnRest<D, U> : never),
    ]
  : A extends [SuperAgentApi<D, infer AP>]
  ? [WrappedApi<D, AP>]
  : A extends [WrappedApi<D, unknown[]>]
  ? A
  : [];

type UseApiReturn<P extends CallParams, D, A extends ApiArray<D> | []> = [
  ApiState<D>,
  WrappedApi<D, P>,
  ...UseApiReturnRest<D, A>,
];

// The first argument should either be options or an API function
//  You can pass in multiple APIs if you want them to share state
export const useApi = <
  InitialData,
  Params extends CallParams,
  ApiRest extends ApiArray<DataType> = [],
  DataType extends InitialData = InitialData,
>(
  arg1: ApiOptions<InitialData> | SuperAgentApi<InitialData, Params>,
  ...apis: ApiRest
) => {
  const initial = initialState(getOptions(arg1));
  const [state, setState] = useApiState<InitialData | DataType>(initial);
  const wrapApi = useWrapApi(setState, transformApi);

  const createWrappedApis = () => {
    const apisToWrap = typeof arg1 === 'object' ? apis : [arg1, ...apis];
    return apisToWrap.map((api) => wrapApi(api));
  };

  const wrappedApis = useRef(createWrappedApis());

  const returnObject = [state, ...wrappedApis.current] as UseApiReturn<
    Params,
    DataType,
    ApiRest
  >;

  return returnObject;
};

type UseFetchParams<T, P extends unknown[]> = [
  SuperAgentApi<T, P> | null | false,
  P,
  unknown[]?,
];

export const useFetch = <DataType, Args extends unknown[]>(
  ...[api, params, dependencies]: UseFetchParams<DataType, Args>
) => {
  type EqualityRef = {
    dependencies?: unknown[];
    params?: Args;
    invalidated?: number;
  };

  const [state, setState] = useApiState(initialState<DataType>());
  const equalityRef = useRef<EqualityRef>({});

  const wrapApi = useWrapApi(setState, transformFetchApi);

  const wrappedApi = useMemo(() => {
    return api ? wrapApi(api) : null;
  }, [api, wrapApi]);

  useEffect(() => {
    if (!wrappedApi) return;

    const equalityCheckObj = {
      dependencies,
      params,
      invalidated: state.invalidated,
    };
    if (equal(equalityRef.current, equalityCheckObj)) return;
    equalityRef.current = equalityCheckObj;

    // Just like it is with getOptions, params can be undefined in tests.
    wrappedApi(...(params || [])).catch((e: unknown) => e);
  }, [params, wrappedApi, dependencies, state.invalidated]);

  if (state?.originalError?.status === 451) throw state.originalError;

  return state;
};
