import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEventHandler,
  MouseEvent,
  Ref,
  useEffect,
} from 'react';

import classNames from 'classnames';

import Label from '@shared/components/FormElements/Label';
import useTranslate from '@shared/components/hooks/useTranslate';
import CalendarIcon from '@shared/components/Icons/CalendarIcon';
import Cross from '@shared/components/Icons/Cross';
import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import { StrictOmit } from '@shared/types';

import s from './index.module.scss';

interface DatePickerInputProps
  extends StrictOmit<
    InputHTMLAttributes<HTMLInputElement>,
    'required' | 'onChange'
  > {
  label: string;
  id: string;
  required?: string | boolean;
  placeholder?: string;
  selectedDateString?: string;
  onFocus: () => void; // FocusEventHandler<HTMLInputElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onChange: (val: string) => void;
  readOnly: boolean;
  autoFocus?: boolean;
  showClearDate?: boolean;
  noInputTyping: boolean;
  className?: string;
  focused?: never;
  onFocusChange?: never;
}

const dumpObsoleteProps = (props: Record<string, any>) => {
  const { appendToBody, ...rest } = props;

  return rest;
};

export const DatePickerInput = forwardRef(
  (
    {
      label,
      id,
      placeholder,
      selectedDateString,
      required,
      onFocus,
      onKeyDown,
      onChange,
      readOnly,
      autoFocus = false,
      showClearDate = false,
      noInputTyping = false,
      className,
      focused: _focused,
      onFocusChange: _onFocusChange,
      ...props
    }: DatePickerInputProps,
    ref: Ref<HTMLInputElement> | undefined,
  ) => {
    props = dumpObsoleteProps(props);

    const translate = useTranslate();

    useEffect(() => {
      // this is a workaround because when alternativeToggleUi is set, the input is display none
      // which prevents the usual focus events from firing.
      if (autoFocus && typeof ref !== 'function' && ref?.current && autoFocus) {
        onFocus();
      }
    }, [ref, autoFocus]);

    return (
      <div className={classNames(s.datePickerInput, readOnly && s.disabled)}>
        {label && <Label htmlFor={id} label={label} required={required} />}
        <div className={s.inputWrap} data-test-id="datePickerInput">
          {/* eslint-disable-next-line local-rules/no-restricted-legacy-elements */}
          <LegacyInput
            {...props}
            className={classNames(
              className,
              noInputTyping && 'pointer-events-none',
            )}
            size={12}
            id={id}
            ref={ref}
            name={id}
            type="text"
            placeholder={
              placeholder
                ? translate(placeholder)
                : translate('label.selectDate')
            } // y-MM-dd
            value={selectedDateString}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange(e.target.value)
            }
            readOnly={readOnly}
            disabled={readOnly}
            autoFocus={autoFocus}
          />
          <div>
            {showClearDate ? (
              <>
                <CalendarIcon className={s.calendarIconLeft} />
                <div className={s.clearDate}>
                  <Cross
                    onClick={(e: MouseEvent<HTMLInputElement>) => {
                      e.stopPropagation();

                      if (!readOnly) {
                        onChange('');
                      }
                    }}
                  />
                </div>
              </>
            ) : (
              <CalendarIcon />
            )}
          </div>
        </div>
      </div>
    );
  },
);
