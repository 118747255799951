export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss' as const; // 1986-09-04T08:30:25
export const ISO_FORMAT_WITHOUT_SECONDS = 'YYYY-MM-DDTHH:mm' as const; // 1986-09-04T08:30
export const TIMESTAMP_MS_FORMAT = 'x' as const;

export const DATE_FORMAT = 'YYYY-MM-DD' as const; // 1986-09-04
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm' as const; // 1986-09-04 08:30
export const DATE_TIME_SECONDS_FORMAT = 'YYYY-MM-DD HH:mm:ss' as const; // 1986-09-04 08:30:00
export const TIME_FORMAT = 'HH:mm' as const; // 08:30
export const TIME_SECONDS_FORMAT = 'HH:mm:ss' as const; // 08:30:25
export const YEAR_FORMAT = 'YYYY' as const; // 1986
export const DAY_OF_MONTH_FORMAT = 'D' as const; // 4 or 14
export const DAY_OF_MONTH_PAD_FORMAT = 'DD' as const; // 04 or 14
export const MONTH_OF_YEAR_FORMAT = 'M' as const; // 2 or 12
export const MONTH_OF_YEAR_PAD_FORMAT = 'MM' as const; // 02 or 12
export const MONTH_DAY_FORMAT = 'MM-DD' as const; // 12-01

export const LOCALE_DATE_FORMAT = 'L' as const; // '09/04/1986', depending on locale
export const LOCALE_DATE_WEEKDAY_FORMAT = 'L dddd' as const; // '09/04/1986 Wednesday', depending on locale
export const LOCALE_DATE_WEEKDAY_SHORT_FORMAT = 'L ddd' as const; // '09/04/1986 Wed', depending on locale
export const LOCALE_DATE_MONTH_NAME_FORMAT = 'LL' as const; // 'September 4, 1986', depending on locale
export const LOCALE_DATE_MONTH_NAME_SHORT_FORMAT = 'll' as const; // 'Sep 4, 1986', depending on locale
export const LOCALE_DATE_NAME_FORMAT = 'ddd ll' as const; // Sun Jan 21, 2019, depending on locale
export const LOCALE_DATE_FULL_NAME_FORMAT = 'dddd ll' as const; // Sunday Jan 21, 2019, depending on locale
export const LOCALE_DATE_TIME_FORMAT = 'L LT' as const; // '09/04/1986 8:30 PM', depending on locale
export const LOCALE_DATE_TIME_SECONDS_FORMAT = 'L LTS' as const; // '09/04/1986 8:30:25 PM', depending on locale
export const LOCALE_DATE_TIME_NAME_FORMAT = 'ddd ll LT' as const; // 'Sun Jan 21, 2019 8:30 PM', depending on locale
export const LOCALE_DATE_MONTH_NAME_TIME_FORMAT = 'll LT' as const; // 'Sep 4, 1986 8:30 PM', depending on locale
export const LOCALE_TIME_FORMAT = 'LT' as const; // '8:30 PM', depending on locale
export const LOCALE_TIME_SECONDS_FORMAT = 'LTS' as const; // '8:30:25 PM', depending on locale
export const LOCALE_DAY_NAME_FORMAT = 'dddd' as const; // 'Sunday', depending on locale
export const LOCALE_DAY_NAME_SHORT_FORMAT = 'ddd' as const; // 'Sun', depending on locale
export const LOCALE_DAY_NAME_COMPACT_FORMAT = 'dd' as const; // 'Su', depending on locale
export const LOCALE_DATE_WEEKDAY_DAY_FORMAT = 'ddd D' as const; // 'Sun 21'

export const EPOCH_TIME = '1970-01-01T00:00:00' as const;

export const SCHEDULE_TIME_TS_FORMAT = 'YYYY-MM-DDTHH:mm:00' as const;
