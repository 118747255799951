import React, { Component } from 'react';

import { isRedirect } from '@reach/router';

import LegacyDiv from '@shared/components/LegacyDiv';

import { logout as logoutApi } from '../../../api/authentication';
import Button from '../../../components/ButtonLogin';
import { BUTTON_VARIANT } from '../../../components/ButtonLogin/constants';
import ErrorReporting from '../../../utils/errorreporting';

import './index.scss';

import { TermsAndConditions } from './TermsAndConditions';

const logout = () => logoutApi().finally(() => window.location.assign('/'));

export default class AppErrorBoundary extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    if (isRedirect(error)) {
      throw error;
    }

    ErrorReporting.reportError(error);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) return children;

    if (error.status === 451) {
      return (
        <TermsAndConditions
          token={error?.response?.headers?.['x-terms-and-conditions-token']}
        />
      );
    }

    return (
      <LegacyDiv className="flex-justify-center min-height-vh-100 width-100">
        <LegacyDiv className="error-boundary-page flex-grow" />
        <LegacyDiv className="flex-row">
          <LegacyDiv className="max-width-100">
            {error.status === 403 ? (
              <LegacyDiv className="error-boundary-page__message">
                <LegacyDiv>Access denied</LegacyDiv>
                <LegacyDiv>
                  <LegacyDiv className="margin-2-bottom">
                    You are not allowed to access this resource.
                  </LegacyDiv>
                  <LegacyDiv>Click the button to reload.</LegacyDiv>
                </LegacyDiv>
              </LegacyDiv>
            ) : (
              <>
                <LegacyDiv className="error-boundary-page__message">
                  <LegacyDiv>Oops...</LegacyDiv>
                  <LegacyDiv>Something went wrong</LegacyDiv>
                  <LegacyDiv>Let’s start again!</LegacyDiv>
                </LegacyDiv>
              </>
            )}
            <LegacyDiv className="error-boundary-page__button">
              <Button
                variant={BUTTON_VARIANT.SECONDARY}
                text="Reload"
                onClick={logout}
              />
            </LegacyDiv>
          </LegacyDiv>
        </LegacyDiv>
      </LegacyDiv>
    );
  }
}
