export enum SCHEDULE_VIEW_MODE_VALUES {
  EMPLOYEE = 'employee',
  BUSINESS = 'business',
}

export const APPROVAL_LEVEL = {
  NONE: 0,
  ONE: 1,
  TWO: 2,
} as const;

export const ADDABLE_ENTITIES = [
  'absence',
  'punch',
  'shift',
  'availability',
] as const;

export const UNAVAILABILITY_REASONS = {
  SHIFT: 'UNAVAILABILITY_REASON_SHIFT',
  ABSENCE: 'UNAVAILABILITY_REASON_ABSENCE',
  AGREEMENT: 'UNAVAILABILITY_REASON_AGREEMENT',
} as const;
