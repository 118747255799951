import hexToRgbaLib from 'hex-rgba';

import { isNil } from '@shared/utils';
import { callWithNever } from '@shared/utils/typeUtils';

import { HexColor, RgbaColor } from './types';

type RgbaTouple = [number, number, number, number];

/**
 * This function normalizes a hex color to a 6-digit hex color.
 * @param {HexColor} hex value to normalize
 * @returns {HexColor} hex color in format #RRGGBB
 */
export const normalizeHex = (hex: HexColor) =>
  hex.length < 7
    ? (`#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}` as const)
    : hex;

export const hexToRgba = (hex?: HexColor, opacity?: number) => {
  return hexToRgbaLib(hex || '#ffffff', opacity || 100) as RgbaColor;
};

const linearize = (channel: number) =>
  channel <= 10 ? channel / 3294 : (channel / 269 + 0.0513) ** 2.4;

export const lightenHex = (color: HexColor, percent: number): HexColor =>
  `#${color
    .replace(/^#/, '')
    .replace(/^(.*)$/, color.length < 6 ? '$1$1' : '$1')
    .replace(/../g, (channel) => {
      const channelValue = parseInt(channel, 16);
      const darkened =
        channelValue +
        (percent > 0 ? 255 - channelValue : channelValue) * (percent / 100);
      return `0${Math.round(Math.min(255, Math.max(0, darkened))).toString(
        16,
      )}`.substr(-2);
    })}`;

function assertRgbaTouple(
  arr: (number | undefined)[],
): asserts arr is RgbaTouple {
  if (arr.length !== 4 || arr.some(isNil)) throw new Error('Invalid color');
}

const relativeLuminance = (color: RgbaColor) => {
  const rgbArr = color
    .replace(/[^\d,]/g, '')
    .split(',')
    .map((channel) => parseInt(channel));
  assertRgbaTouple(rgbArr);

  const linearRgbaArr = rgbArr.map(linearize);
  assertRgbaTouple(linearRgbaArr);
  const [r, g, b] = linearRgbaArr;

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const isDark = (color: HexColor) => {
  const colorLuminance = relativeLuminance(hexToRgba(color));
  const whiteLuminance = 1;
  const blackLuminance = 0;

  const whiteContrast = (whiteLuminance + 0.05) / (colorLuminance + 0.05);
  const blackContrast = (colorLuminance + 0.05) / (blackLuminance + 0.05);

  return whiteContrast > blackContrast;
};

export const contrastRatio = (color: HexColor) =>
  isDark(color) ? '#ffffff' : '#000000';

export const isHexColor = (color: string): color is HexColor =>
  /^#[0-9A-F]{6}$/i.test(color);

// Why the hell is it called "decimalToHexColor" when we apparently keep calling it with strings
// and then it doesn't even return a hex?
export const decimalToHexColor = <T extends number | string | null>(
  color?: T,
) => {
  // This is quite ridiculous but we've written tests that assume this function always returns a string
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  if (isNil(color)) return callWithNever((_p) => '' as const, color);

  if (typeof color === 'string') {
    return callWithNever((p) => p, color);
  }

  if (Number.isNaN(typeof color === 'number' ? color : parseInt(color))) {
    throw new Error(`Invalid color: ${color}`);
  }

  return callWithNever(
    (colorHexValue) =>
      (colorHexValue.length < 6
        ? `#${colorHexValue.padStart(6, '0')}`
        : `#${colorHexValue}`) as HexColor,
    color.toString(16).slice(-6),
  );
};

/* eslint-disable no-bitwise */
export const decimalToHsl = (color: number) => {
  const r = ((color >> 16) & 255) / 255;
  const g = ((color >> 8) & 255) / 255;
  const b = (color & 255) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = (max + min) / 2;
  const l = (max + min) / 2;

  if (max === min) {
    h = 0;
    s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) / 6;
        break;
      case g:
        h = ((b - r) / d + 2) / 6;
        break;
      case b:
        h = ((r - g) / d + 4) / 6;
        break;
      default:
        break;
    }
  }

  return [
    Math.round(h * 360),
    Math.round(s * 100),
    Math.round(l * 100),
  ] as const;
};
/* eslint-enable no-bitwise */

export const hexToHsl = (hexColor: HexColor) =>
  decimalToHsl(parseInt(normalizeHex(hexColor).slice(1), 16));
