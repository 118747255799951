import React, {
  forwardRef,
  KeyboardEvent,
  Ref,
  useEffect,
  useState,
} from 'react';

import classNames from 'classnames';
import t from 'prop-types';
import { DayModifiers } from 'react-day-picker';

import useTranslate from '@shared/components/hooks/useTranslate';
import { format, isValidDate, stringToDate } from '@shared/utils/time';
import { DATE_FORMAT, LOCALE_DATE_FORMAT } from '@shared/utils/time/constants';

import 'react-day-picker/dist/style.css';

import Tooltip from 'react-tooltip-lite';

import LegacyDiv from '@shared/components/LegacyDiv';
import { DateTime } from '@shared/utils/time/types';

import { withFormControl } from '../../Form';
import { DatePickerInput } from './DatePickerInput';
import {
  getDisabledMatcher,
  getInitialValueStr,
  getInitialValueUnconstrained,
  getMixMaxDates,
} from './helpers';
import useClickOutside from './hooks/useClickOutside';
import { ReactDayPicker } from './ReactDayPicker';
import { DatePickerBaseProps } from './types';

import s from './ReactDayPicker.module.scss';

export type OnChangeSingleParams = (date: string | null) => void;

interface DatePickerSingleProps extends DatePickerBaseProps {
  id?: string;
  value?: string | Date | DateTime;
  onChange: OnChangeSingleParams;
  showClearDate?: boolean;
}

export const ReactDayPickerSingle = forwardRef(
  (
    {
      isOutsideRange = () => false,
      futureYearsRange = 9,
      renderCustomWeekNumberDayContents = false,
      label = '',
      info,
      error,
      numberOfMonths = 1,
      placeholder,
      required,
      id = crypto.randomUUID(),
      onChange,
      value,
      dateFormat = DATE_FORMAT,
      displayFormat = LOCALE_DATE_FORMAT,
      isClearable,
      minAvailableDate,
      maxAvailableDate,
      className: _className,
      onBlur: _onBlur,
      name: _name,
      readOnly = false,
      disabled = false,
      'data-test-id': dataTestId = crypto.randomUUID(),
      openDirection = 'down',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      anchorDirection = 'left',
      alternativeToggleUi,
      showClearDate = false,
      noInputTyping = false,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isRange = false, // TODO Ed Brown: Remove in future. Unused but present because of legacy DatePicker
      ...props
    }: DatePickerSingleProps,
    ref: Ref<HTMLInputElement> | undefined,
  ) => {
    const [pickerFocused, setPickerFocused] = useState(false);
    const [errMsg, setErrMsg] = useState<string | undefined>(error);
    const translate = useTranslate();
    const { min, max } = getMixMaxDates(minAvailableDate, maxAvailableDate);
    const disabledMatcher = getDisabledMatcher(min, max);
    const selectedDay = getInitialValueUnconstrained(value);

    if (disabled) {
      // readOnly and disabled give the same effect in this date picker
      readOnly = true;
    }

    const selectedDateString = value
      ? getInitialValueStr(selectedDay, displayFormat)
      : undefined;

    useEffect(() => {
      setErrMsg(error);
    }, [error]);

    const handleChangeFromInput = (valueStr: string) => {
      if (valueStr && valueStr !== '') {
        // convert string to moment (based on tz will correctly handle different date-string formats)
        const momentDate = stringToDate(valueStr, displayFormat);

        setErrMsg(undefined);

        setPickerFocused(false);
        onChange(
          isValidDate(momentDate) ? format(momentDate, dateFormat) : null,
        );
      } else {
        setPickerFocused(false);
        onChange(null);
      }
    };

    const handleDayClick = (day: Date, { selected }: DayModifiers) => {
      if (isOutsideRange(day)) {
        return false;
      }

      if (selected) {
        // we have selected the same date, so just close & return, otherwise it will de-select the date
        setPickerFocused(false);
        return;
      }

      setErrMsg(undefined);
      setPickerFocused(false);
      onChange(selected ? null : getInitialValueStr(day, dateFormat)); // left in ternary for clarity of what should happen if we allow de-selection
    };

    const { setClickOutsideRef } = useClickOutside(() =>
      setPickerFocused(false),
    );

    return (
      <LegacyDiv
        className={classNames(s.datePickerWrap, readOnly && s.readOnly)}
        data-test-id={dataTestId}>
        <Tooltip
          className={s.datePickerPopoverTooltip}
          padding={0}
          arrow={false}
          eventToggle="onClick"
          eventOff="onBlur"
          useHover={false}
          isOpen={pickerFocused}
          onToggle={(open) => {
            if (open && !pickerFocused && !readOnly) {
              setPickerFocused(true);
            }
          }}
          direction={openDirection}
          content={
            <LegacyDiv
              data-test-id={`datePickerPopover_${dataTestId}`}
              className={s.datePickerPopover}
              ref={setClickOutsideRef}>
              <ReactDayPicker
                {...props}
                mode="single"
                data-test-id="day-picker"
                pickerFocused={pickerFocused}
                selectedDay={selectedDay}
                numberOfMonths={numberOfMonths}
                disabledMatcher={disabledMatcher}
                futureYearsRange={futureYearsRange}
                renderCustomWeekNumberDayContents={
                  renderCustomWeekNumberDayContents
                }
                handleDateSelectSingle={handleDayClick}
                handleDateSelectRange={undefined}
                isOutsideRange={isOutsideRange}
              />
            </LegacyDiv>
          }>
          {alternativeToggleUi}
          <LegacyDiv
            className={s.dateInput}
            style={{
              display: alternativeToggleUi ? 'none' : 'flex',
            }}>
            <DatePickerInput
              {...props}
              label={label}
              id={id}
              ref={ref}
              placeholder={placeholder}
              selectedDateString={selectedDateString}
              onFocus={() => setPickerFocused(true)}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.key === 'Tab') setPickerFocused(false);
              }}
              onChange={handleChangeFromInput}
              readOnly={readOnly}
              required={required}
              showClearDate={showClearDate}
              noInputTyping={noInputTyping}
            />
          </LegacyDiv>
        </Tooltip>

        {errMsg && <span className={s.errorMsg}>{translate(errMsg)}</span>}

        {info && <span className={s.infoMsg}>{translate(info)}</span>}
      </LegacyDiv>
    );
  },
);

ReactDayPickerSingle.displayName = 'ReactDayPickerSingle';
ReactDayPickerSingle.propTypes = {
  label: t.string,
  required: t.oneOfType([t.bool, t.string]),
  error: t.string,
  info: t.string,
  placeholder: t.string,
};

export const FormDatePicker = withFormControl(ReactDayPickerSingle);

export type ReactDayPickerSingleWithFormControlProps = React.ComponentProps<
  typeof FormDatePicker
> & {
  useNewDatePicker?: boolean;
};

FormDatePicker.propTypes = {
  name: t.string.isRequired,
};
