import React, { useEffect } from 'react';

import { WindowLocation } from '@reach/router';

import AuthPageWrapper from '@shared/components/AuthPageWrapper';
import Button from '@shared/components/ButtonLogin';
import { BUTTON_VARIANT } from '@shared/components/ButtonLogin/constants';
import CodeSnippet from '@shared/components/CodeSnippet';
import useToggle from '@shared/components/hooks/useToggle';
import LegacyDiv from '@shared/components/LegacyDiv';
import Link from '@shared/components/Link';
import ConfirmModal from '@shared/components/Modal/Confirm';
import { parseQueryString, useNavigate } from '@shared/utils/route';

import styles from './index.module.scss';

type ErrorPageSSOProps = {
  location: WindowLocation;
  pathToGoBack: string;
};

const ErrorPageSSO = ({
  location: { search },
  pathToGoBack,
}: ErrorPageSSOProps) => {
  const [isStackTraceModalOpen, { check, uncheck }] = useToggle(false);
  const { errorHeader, errorMessage, errorDetails } = parseQueryString(search);

  useEffect(() => {
    if (errorDetails) {
      // if in debug mode, we show the stacktrace modal
      check();
    }
  }, [errorDetails]);

  const navigate = useNavigate();
  const handleCloseStackTraceModal = () => uncheck();
  const handleBackClick = () => navigate(pathToGoBack);

  return (
    <AuthPageWrapper>
      <LegacyDiv className="flex-center margin-4-bottom">
        <h1 className="login-header padding-0">{errorHeader}</h1>
      </LegacyDiv>

      <LegacyDiv className="flex-one">
        <LegacyDiv className="margin-4-bottom">{errorMessage}</LegacyDiv>
      </LegacyDiv>
      <Button
        onClick={handleBackClick}
        text="Back" // This button text is hard coded. This component is not inside the LanguageProvider.
        data-test-id="backButton"
        variant={BUTTON_VARIANT.LOGIN}
        shouldGrow
      />
      <LegacyDiv className="text-center padding-6-top">
        <span>Please read our</span>
        <Link
          data-test-id="errorPageSSO__privacyLink"
          to="https://www.quinyx.com/privacy#quinyx-application"
          className="link-primary padding-1-left"
          target="_blank"
          external>
          Privacy Policy
        </Link>
      </LegacyDiv>
      <ConfirmModal
        open={isStackTraceModalOpen}
        title="Stack trace"
        handlePrimaryAction={handleCloseStackTraceModal}
        handleSecondaryAction={handleCloseStackTraceModal}
        primaryActionText="Ok"
        secondaryActionText="Close">
        <LegacyDiv className={styles.stackTraceContainer}>
          <CodeSnippet code={String(errorDetails)} />
        </LegacyDiv>
      </ConfirmModal>
    </AuthPageWrapper>
  );
};

export default ErrorPageSSO;
