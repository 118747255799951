import { useContext } from 'react';

import { AccountContext, MealBreaksContext } from '@shared/components/Context';
import { MODULE_NAME } from '@shared/constants/moduleAndPermissions';
import { Group } from '@shared/types';
import { assertContextHasValue } from '@shared/utils/assertContextAvailable';

import { useHasModule } from '../accessrights';

export const useGroups = () => {
  const { groups } = useContext(AccountContext);
  return groups as Record<string, Group>;
};

export const useMainUnitGroup = () => {
  const { groups, mainUnitGroupId } = useContext(AccountContext);
  return groups?.[mainUnitGroupId as number] as Group;
};

export const useCurrentGroup = () => {
  const { currentGroupId, groups } = useContext(AccountContext);
  return groups?.[currentGroupId as number] as Group;
};

export const useCurrentAccount = () => {
  const { groups, currentAccountId } = useContext(AccountContext);
  return groups?.[currentAccountId as number] as Group;
};

export const useMealBreaksSettings = () => {
  const data = useContext(MealBreaksContext);
  assertContextHasValue(data, 'Meal breaks context');
  return data;
};

export const useIsFLPOnlyAccount = () => {
  const account = useCurrentAccount();
  const { modules } = account.accesses;
  const hasTaskManagement = useHasModule(MODULE_NAME.TASK_MANAGEMENT);

  if (modules.length > 1) return false;

  return hasTaskManagement;
};
