export const SchedulerDayComponentTypes = {
  SCHEDULE_SHIFT_ITEM: 'SCHEDULE_SHIFT_ITEM',
  SCHEDULE_PUNCH_ITEM: 'SCHEDULE_PUNCH_ITEM',
  SCHEDULE_LEAVE_REQUEST: 'SCHEDULE_LEAVE_REQUEST',
  SCHEDULE_ABSENCE: 'SCHEDULE_ABSENCE',
  SCHEDULE_AVAILABILITY: 'SCHEDULE_AVAILABILITY',
  SCHEDULE_UNAVAILABILITY: 'SCHEDULE_UNAVAILABILITY',
  SCHEDULE_AWAY_GROUP_PUNCH_ITEM: 'SCHEDULE_AWAY_GROUP_PUNCH_ITEM',
} as const;

export const SchedulerIndicatorTypes = {
  HOURS: 'HOURS',
  DAYS: 'DAYS',
} as const;

export const OVERLAPPING_SHIFTS_ACTION = Object.freeze({
  SPLIT: 'SPLIT',
  OVERLAP: 'OVERLAP',
});

export const splitByOptions = {
  SHIFT: 1,
  DAY: 2,
};

export const referrerTypes = {
  QUICK_ADD: 'quick-add',
  STANDARD_ADD: 'standard-add',
} as const;

export type TReferrerType = (typeof referrerTypes)[keyof typeof referrerTypes];

export const ANALYTICS_TARGET = {
  QUICK_ADD: 'quick-add',
  TOOLBAR: 'toolbar',
} as const;

export type TAnalyticsTarget =
  (typeof ANALYTICS_TARGET)[keyof typeof ANALYTICS_TARGET];
