import React, { createContext, useContext } from 'react';

import { useCurrentGroup } from '@ManagerPortal/containers/hooks/account';

const Feature = createContext();

const hasPermission = (userLevel, requiredLevel) => {
  return userLevel === 'WRITE'
    ? ['WRITE', 'READ'].includes(requiredLevel.toUpperCase())
    : userLevel === requiredLevel.toUpperCase();
};

const FeatureProvider = ({ children }) => {
  const permissions = useCurrentGroup()?.accesses?.permissions ?? [];
  const value = permissions.reduce((acc, curr) => {
    acc[curr.name] = curr;
    return acc;
  }, {});

  return <Feature.Provider value={value}>{children}</Feature.Provider>;
};

const useHasPermission = (name, requiredLevel) => {
  const accessRights = useContext(Feature)[name] || {};
  return hasPermission(accessRights.access, requiredLevel);
};

const FeatureToggle = ({
  children,
  name,
  requiredLevel = 'READ',
  fallback = null,
}) => {
  if (useHasPermission(name, requiredLevel)) {
    return children;
  }
  return fallback;
};

export { FeatureProvider, FeatureToggle, useHasPermission };
