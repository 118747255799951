import { scaleTime } from 'd3-scale';

import { round } from '@shared/utils';
import { removeTZ } from '@shared/utils/time';
import { DateTime, TIsoFormat } from '@shared/utils/time/types';

import { UNAVAILABILITY_REASONS } from '@ManagerPortal/containers/Schedule/Main/const';
import {
  AbsenceItem,
  AnyScheduleItemType,
  AvailabilityItem,
  PunchItem,
  ShiftItem,
  TAwayGroupPunchItem,
  TUnavailabilityBasic,
  UnavailabilityItem,
} from '@ManagerPortal/containers/Schedule/Main/types';

import { SchedulerDayComponentTypes } from '../constants';

export * from './indexJs';

type TSchedulerDayComponentType =
  (typeof SchedulerDayComponentTypes)[keyof typeof SchedulerDayComponentTypes];

export const calculateRelativePosition = ({
  domainStartDate,
  domainEndDate,
  startDate,
  endDate,
  schedulerDayComponentType,
  isSingleDay,
}: {
  domainStartDate: DateTime | TIsoFormat;
  domainEndDate: DateTime | TIsoFormat;
  startDate: DateTime | TIsoFormat;
  endDate: DateTime | TIsoFormat;
  schedulerDayComponentType: TSchedulerDayComponentType | null;
  isSingleDay: boolean;
}): React.CSSProperties => {
  if (!isSingleDay) {
    return {
      position: 'relative',
    };
  }

  const d3ScaleTime = scaleTime()
    .domain([removeTZ(domainStartDate), removeTZ(domainEndDate)])
    .range([0, 100]);

  const left = d3ScaleTime(removeTZ(startDate));
  const right = 100 - d3ScaleTime(removeTZ(endDate));

  if (
    schedulerDayComponentType === SchedulerDayComponentTypes.SCHEDULE_PUNCH_ITEM
  ) {
    return {
      position: 'absolute',
      left: `${left}%`,
      right: `${right}%`,
    };
  }

  return {
    position: 'absolute',
    left: `${Math.max(left, 0)}%`,
    right: `${Math.max(right, 0)}%`,
  };
};

export const getHoursFormatDuration = (hours: number) => {
  const roundedHours = round(hours);
  return Number.isInteger(roundedHours)
    ? roundedHours.toPrecision(hours < 10 ? 2 : 3)
    : String(roundedHours);
};

export const isAvailabilityComponent = (obj: {
  componentType: string;
}): obj is AvailabilityItem => {
  const { componentType } = obj;

  return componentType === SchedulerDayComponentTypes.SCHEDULE_AVAILABILITY;
};

export const isShiftComponent = (obj: {
  componentType: string;
}): obj is ShiftItem => {
  const { componentType } = obj;

  return componentType === SchedulerDayComponentTypes.SCHEDULE_SHIFT_ITEM;
};

export const isAbsenceComponent = (obj: {
  componentType: string;
}): obj is AbsenceItem => {
  const { componentType } = obj;

  return componentType === SchedulerDayComponentTypes.SCHEDULE_ABSENCE;
};

export const isPunchComponent = (obj: {
  componentType: string;
}): obj is PunchItem => {
  const { componentType } = obj;

  return componentType === SchedulerDayComponentTypes.SCHEDULE_PUNCH_ITEM;
};

export const isUnavailabilityComponent = (obj?: {
  componentType: string;
}): obj is UnavailabilityItem =>
  obj?.componentType === SchedulerDayComponentTypes.SCHEDULE_UNAVAILABILITY;

export const isAwayGroupPunchComponent = (
  obj: AnyScheduleItemType,
): obj is TAwayGroupPunchItem =>
  obj.componentType ===
  SchedulerDayComponentTypes.SCHEDULE_AWAY_GROUP_PUNCH_ITEM;

export const isAwayGroupItem = (
  obj: AnyScheduleItemType,
): obj is TAwayGroupPunchItem | UnavailabilityItem =>
  isUnavailabilityComponent(obj) || isAwayGroupPunchComponent(obj);

export const isBackgroundUnavailabilityComponent = (
  component?: AnyScheduleItemType,
): component is UnavailabilityItem<TUnavailabilityBasic> =>
  isUnavailabilityComponent(component) &&
  component.reason === UNAVAILABILITY_REASONS.AGREEMENT;

export const getShiftPunches = (
  { id }: { id: number | string },
  dayComponents: AnyScheduleItemType[],
) =>
  dayComponents.filter(
    (component): component is PunchItem =>
      isPunchComponent(component) && component.shiftId === id,
  );
