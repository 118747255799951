import { del, get, post, put } from '..';

type CreateTermsParamsType = {
  domainGroupId: number;
  terms?: string;
  validityInDays: number;
  enforceAcceptance: boolean;
};

type UpdateTermsParamsType = CreateTermsParamsType & {
  id: string;
};

export type FetchTermsData = {
  terms: string;
  validityInDays: number;
  id: string;
};

export const createTerms = (params: CreateTermsParamsType) =>
  post<FetchTermsData>(
    '/v1/security/terms-and-conditions/domain-settings',
    params,
  );

export const fetchTerms = (domainId: number) =>
  get<FetchTermsData>(
    `/v1/security/terms-and-conditions/domain-settings/domain-id/${domainId}`,
  );

export const fetchTermsByToken = (token: string) =>
  get<FetchTermsData>(
    `/v1/security/terms-and-conditions/by-token?token=${token}`,
  );

export const updateTerms = (params: UpdateTermsParamsType) =>
  put('/v1/security/terms-and-conditions/domain-settings', params);

export const deleteTerms = (termsId: string) =>
  del(`/v1/security/terms-and-conditions/domain-settings/${termsId}`);

export const acceptTerms = (token: string) =>
  post('/v1/security/terms-and-conditions/by-token', { token });
