import { MODULE_NAME } from '@shared/constants/moduleAndPermissions';
import { Permission, PermissionLevel } from '@shared/types';

import { hasModule, hasPermission } from '@ManagerPortal/utils/accessrights';

import { useCurrentAccount, useCurrentGroup } from '../account';
import { useCurrentUser } from '../user';

export { useHasPermission } from '@ManagerPortal/components/FeatureFlag';
export {
  PERMISSION,
  PERMISSION_LEVEL,
} from '@shared/constants/moduleAndPermissions';
export const useIsSystemAdmin = () => {
  const user = useCurrentUser();
  return user.systemAdmin;
};

export const useHasModule = (name: MODULE_NAME) => {
  const currentGroup = useCurrentGroup();
  if (!currentGroup) return false;
  return hasModule(name, currentGroup);
};

export const useHasOneOfModules = (names: MODULE_NAME[]) => {
  const currentGroup = useCurrentGroup();
  if (!Array.isArray(names)) {
    return false;
  }
  return names.some((module) => hasModule(module, currentGroup));
};

export const useHasRequiredPermissions = (
  names: Permission[],
  level: PermissionLevel,
) => {
  const currentGroup = useCurrentGroup();
  if (!Array.isArray(names)) {
    return false;
  }
  return names.every((permission) =>
    hasPermission(permission, level, currentGroup),
  );
};

export const useHasAccountPermission = (
  name: Permission,
  level: PermissionLevel,
) => {
  const currentAccount = useCurrentAccount();
  return hasPermission(name, level, currentAccount);
};

export const useHasCurrentGroupPermission = (
  name: Permission,
  level: PermissionLevel,
) => {
  const currentGroup = useCurrentGroup();
  return hasPermission(name, level, currentGroup);
};
