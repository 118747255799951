// Mainly use the hooks, these utils should only be used if you
//  need to check other groups than the current one

import { MODULE_NAME } from '@shared/constants/moduleAndPermissions';
import { Group, Permission, PermissionLevel } from '@shared/types';

export const hasModule = (moduleName: MODULE_NAME, group: Group) =>
  group.accesses.modules.includes(moduleName);

export const hasPermission = (
  name: Permission,
  level: PermissionLevel,
  group: Group,
) => {
  const userLevel =
    (
      group.accesses.permissions.find(
        (permission) => permission.name === name.toUpperCase(),
      ) || {}
    ).access || '';

  return userLevel === 'WRITE'
    ? ['WRITE', 'READ'].includes(level.toUpperCase())
    : userLevel === level.toUpperCase();
};

export const hasAccess = (group: Group) =>
  !!group.accesses.permissions.find(
    (permission) => permission.access !== 'NONE',
  );

export const isCurrentGroup = (group: Group, currentGroupId: number) =>
  group.id === currentGroupId || group.parentGroupId === currentGroupId;

export const isFLPOnlyAccount = (group: Group) => {
  const { modules } = group.accesses;
  if (modules.length > 1) return false;

  return hasModule(MODULE_NAME.TASK_MANAGEMENT, group);
};
