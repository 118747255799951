import { useMemo } from 'react';

import { SHIFT_STATUS } from '@shared/constants';
import { TIME_FORMAT } from '@shared/utils/time/constants';

import { asMilliseconds } from '../../../../shared/utils/time';
import { SchedulerDayComponentTypes } from '../constants';

export const isLeaveRequestComponent = (component) =>
  component.componentType === SchedulerDayComponentTypes.SCHEDULE_LEAVE_REQUEST;

export const areTasksOn = (features) => features.includes('tasks');
export const areShiftsOn = (features) => features.includes('shifts');
export const arePunchesOn = (features) => features.includes('punches');

export const calculateShiftTypeColorId = (shiftTypeId) => (shiftTypeId % 11) + 1; // prettier-ignore

export const roundNominalHour = (number) => {
  if (Math.round(number) !== number) {
    const twoDecimals = number.toFixed(2).toString();
    if (twoDecimals.endsWith('0')) {
      return twoDecimals.substr(0, twoDecimals.length - 1);
    }

    return twoDecimals;
  }
  return number.toString();
};

export const shiftContainerHeight = (
  shift,
  isSingleDay,
  hasShiftPunches,
  features,
) => {
  if (shift.leaveShift) return 40;

  const isShiftBookingRequested = !!(shift && shift.bookingRequest);
  const isCommented = !!(shift && shift.comment);
  const matchingSubShifts = shift.subShifts.filter(
    (subShift) => subShift.isMatchingFilter,
  );

  const iconsHeight = isShiftBookingRequested && isSingleDay ? 10 : 0;
  const lendShiftHeight = shift.isLendShift ? 20 : 0;
  const commentHeight = isCommented ? 16 : 0;
  const subShiftsHeight =
    areTasksOn(features) && !isSingleDay ? matchingSubShifts.length * 19 : 0;
  const punchesHeight =
    arePunchesOn(features) && hasShiftPunches && isSingleDay ? 36 : 0;

  return (
    40 +
    iconsHeight +
    lendShiftHeight +
    commentHeight +
    subShiftsHeight +
    punchesHeight
  );
};

export const useAttestedStatus = (
  employeeId,
  absencesMetadata,
  punchesMetadata,
  isAttest,
) =>
  useMemo(() => {
    const emptyMetaData = {
      total: 0,
      attested: 0,
    };
    const employeeAbsenceData =
      absencesMetadata?.employee?.[employeeId] || emptyMetaData;
    const employeePunchData =
      punchesMetadata?.employee?.[employeeId] || emptyMetaData;

    const isEverythingAttested = () =>
      !!(
        employeePunchData &&
        employeePunchData.total === employeePunchData.attested &&
        employeeAbsenceData &&
        employeeAbsenceData.total === employeeAbsenceData.attested &&
        employeeAbsenceData.total + employeePunchData.total > 0
      ); // at least there is something that is attested

    const isEverythingUnattested = () =>
      employeePunchData.attested === 0 && employeeAbsenceData.attested === 0;

    return {
      isRowAttested: isAttest && isEverythingAttested(),
      isRowUnattested: isAttest && isEverythingUnattested(),
    };
  }, [punchesMetadata, absencesMetadata, isAttest]);

export const isUnassigned = (employee) =>
  !employee || (employee && employee.id === SHIFT_STATUS.UNASSIGNED);

// just to handle some specific formatting units in asMilliseconds
const getUnitMs = (unit) => {
  switch (unit) {
    case 'HH':
      unit = 'h';
      break;
    case 'mm':
      unit = 'm';
      break;
    case 'ss':
      unit = 's';
      break;
    default:
  }
  return asMilliseconds(1, unit);
};
const padLeft = (num) => `${num < 10 ? 0 : ''}${num}`;
/**
 * util for duration format with infinite senior unit
 * (e.g. 2days 10min with 'HH:mm' format will be '48:10' instead of '00:10' in moment)
 * @param {number} time - duration of some period
 * @param {string} format - template for duration formatting
 * @param {string} unit - specifies unit of time param (e.g. "h" - hours)
 * @returns {string} duration formatted with given string template
 */
export const getTimeFormatDuration = ({
  time,
  format = TIME_FORMAT,
  unit = 'ms',
}) => {
  // find all possible units in format string
  const fractions = format.match(/HH|H|mm|m|ss|s/g);
  // sort them to get senior units first (e.g. hours, minutes, seconds)
  const sortedFractions = fractions.sort((a, b) => getUnitMs(b) - getUnitMs(a));

  let timeMs = asMilliseconds(time, unit);

  // replace all of the fractions matches in the format string
  return sortedFractions.reduce((result, fraction) => {
    const fractionMs = getUnitMs(fraction);
    // get count of this units in given time
    const value = Math.floor(timeMs / fractionMs);

    // add leading zeros if fraction of 2 chars (e.g. "HH" = "02", not "2")
    const paddedValue = fraction.length === 2 ? padLeft(value) : value;

    // save remainder for next fraction
    timeMs %= fractionMs;

    return result.replace(fraction, paddedValue);
  }, format);
};

export const getHourFromTimeString = (timeString) => {
  return Number(timeString.split(':')[0]);
};
