import React, { useState } from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';
import { navigate, parseQueryString } from '@shared/utils/route';

import { post } from '../../../ManagerPortal/api';
import { getRequestUrl } from '../../api/utils';
import AuthPageWrapper from '../../components/AuthPageWrapper';
import Button from '../../components/ButtonLogin';
import { BUTTON_VARIANT } from '../../components/ButtonLogin/constants';
import Form from '../../components/Form';
import FormControl from '../../components/Form/FormControl';
import FormGroup from '../../components/Form/FormGroup';
import Label from '../../components/Form/Label';
import Link from '../../components/Link';
import Translate from '../../components/Translate';
import { isEmail, isRequired } from '../../utils/validators';
import {
  createValidator,
  validateAll,
} from '../../utils/validators/validator-model';

const validators = {
  email: [
    createValidator(isRequired, 'An email is required!'),
    createValidator(isEmail, 'Please enter a valid email'),
  ],
};

const ResetPassword = ({ location: { search } }) => {
  const [state, setState] = useState({
    email: {
      value: '',
    },
  });
  const { domainGroupId } = parseQueryString(search);

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      email: { value },
    } = state;

    const { email: emailValidators } = validators;
    const validationMessage = validateAll(emailValidators, value);
    if (validationMessage) {
      setState({
        email: {
          value,
          error: validationMessage,
        },
      });
      return;
    }
    const requestUrl = getRequestUrl('resetpassword');
    post(
      requestUrl,
      { email: value, domainGroupId: +domainGroupId },
      'RESET_PASS',
    )
      .then(() => {
        setState({
          success: 'We have sent you some instructions to reset your password.',
        });
      })
      .catch(() => {
        setState({ error: 'Something seems wrong, please try again.' });
      });
  };

  const handleChange = (e) => {
    const {
      target: { id, value },
    } = e;
    setState({
      [id]: {
        value,
      },
    });
  };

  const handleGoBack = () => navigate('/');

  const {
    error: formError,
    loading,
    email: { value, error } = {},
    success,
  } = state;
  return (
    <AuthPageWrapper>
      <LegacyDiv className="flex-center margin-4-bottom">
        <h1 className="login-header padding-0">Did you forget something?</h1>
      </LegacyDiv>

      <LegacyDiv className="flex-one">
        {success ? (
          <LegacyDiv className="height-100 flex-space-between">
            <p>
              <Translate>{success}</Translate>
            </p>
            <Button
              type="button"
              text="Go back"
              onClick={handleGoBack}
              data-test-id="backButton"
              variant={BUTTON_VARIANT.LOGIN}
              shouldGrow
            />
          </LegacyDiv>
        ) : (
          <>
            <LegacyDiv className="margin-4-bottom">
              Please confirm your email address so that we can send you a
              recovery link
            </LegacyDiv>

            <Form
              className="flex flex-one flex-column flex-space-between"
              onSubmit={handleSubmit}>
              <LegacyDiv>
                <FormGroup
                  feedback={
                    error && (
                      <span className="font__xSmallRegular color-warning">
                        <Translate>{error}</Translate>
                      </span>
                    )
                  }>
                  <Label htmlFor="email">
                    <Translate>Email</Translate>
                  </Label>
                  <FormControl
                    id="email"
                    type="email"
                    value={value}
                    onChange={handleChange}
                    data-test-id="emailInput"
                  />
                </FormGroup>
                {formError && (
                  <p>
                    <Translate>{formError}</Translate>
                  </p>
                )}
              </LegacyDiv>
              <LegacyDiv className="flex-row">
                <LegacyDiv className="flex-one padding-1-right">
                  <Button
                    text="Send"
                    data-test-id="logInButton"
                    variant={BUTTON_VARIANT.LOGIN}
                    isLoading={loading}
                    shouldGrow
                  />
                </LegacyDiv>
                <LegacyDiv className="flex-one padding-1-left">
                  <Button
                    type="button"
                    text="Cancel"
                    onClick={handleGoBack}
                    data-test-id="backButton"
                    variant={BUTTON_VARIANT.LOGIN_SECONDARY}
                    shouldGrow
                  />
                </LegacyDiv>
              </LegacyDiv>
            </Form>
          </>
        )}
      </LegacyDiv>

      <LegacyDiv className="text-center padding-6-top">
        <span>
          <span>Please read our</span>
          <Link
            to="https://www.quinyx.com/privacy#quinyx-application"
            className="link-primary padding-1-left"
            target="_blank"
            external>
            Privacy Policy
          </Link>
        </span>
      </LegacyDiv>
    </AuthPageWrapper>
  );
};

export default ResetPassword;
