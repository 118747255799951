import { ENVIRONMENT, HOSTNAME } from '@shared/constants';
import { createFeatureToggle } from '@shared/createFeatureToggle';

export const strictErrorCrashingToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST],
});

// audit logs feature is enabled everywhere except for Qdaily (and it will never be)
export const auditLogsNoQdailyFeatureToggle = createFeatureToggle({
  showOnHostnames: [
    HOSTNAME.TEST,
    HOSTNAME.STAGE,
    HOSTNAME.STAGE_RC,
    HOSTNAME.PRODUCTION,
    HOSTNAME.VERCEL,
  ],
});

export const auditLogsFeatureToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const agreememtTemplateWorkingHoursUIToggle = createFeatureToggle({
  showOnHostnames: [],
});

export const agreememtTemplateWorkingTimeRulesUIToggle = createFeatureToggle({
  showOnHostnames: [],
});

// cleanup related code when we decide to stop the support of the old OHs
export const oldOptimalHeadcountsToggle = true;

export const bankHolidaysSalesAndSalaryAffectingUIToggle = createFeatureToggle({
  showOnHostnames: [],
});

export const scheduleDragDropDebuggingToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const newDatePickerFeatureToggle = createFeatureToggle({
  showOnHostnames: [], // global toggle is 'off'. Using prop useNewDatePicker so that only enabled on case by case basis during rollout
});

export const analythicsScriptsToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

// This disables optimization of rendering the print component only on print
export const printScheduleDevToggle = createFeatureToggle({
  showOnHostnames: [],
});

export const reactRouterDevToggle = createFeatureToggle({
  showOnHostnames: [], // disabled until after CF!
});

export const eSigningEditIconFeatureToggle = createFeatureToggle({
  showOnHostnames: [],
});

export const offerShiftsFeatureToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const collaborationGroupFeatureToggle = createFeatureToggle({
  showOnHostnames: [],
});

export const scheduleBulkEditDebuggingToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const performanceMonitoringToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST],
});

export const experimentalScheduleViews = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const scheduleComplianceRuleSettingsAndFiltersToggle =
  createFeatureToggle({
    showOnHostnames: [HOSTNAME.LOCAL_TEST, HOSTNAME.VERCEL],
  });

export const scheduleOptimisticUpdates = createFeatureToggle({
  showOnEnvironment: ENVIRONMENT.DEVELOPMENT,
});

export const reactQueryFetchingToggle = createFeatureToggle({
  showOnEnvironment: ENVIRONMENT.DEVELOPMENT,
});

export const employeeHoursToggle = createFeatureToggle({
  showOnEnvironment: ENVIRONMENT.DEVELOPMENT,
});

export const salaryWarningsStoreToggle = createFeatureToggle({
  showOnEnvironment: ENVIRONMENT.DEVELOPMENT,
});

export const mobileNavbarColorToggle = createFeatureToggle({
  showOnHostnames: [HOSTNAME.TEST, HOSTNAME.VERCEL],
});

export const employeeSmartListInBulkEdit = createFeatureToggle({
  showOnHostnames: [],
});
