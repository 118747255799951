import React from 'react';

import { CSSThemeProvider } from '@quinyx/ui';
import { Redirect, Router } from '@reach/router';

import LegacyDiv from '@shared/components/LegacyDiv';
import { BrowserLocationProvider } from '@shared/utils/route/wrappers/BrowserLocationProvider';

import ErrorPageSSO from '../ErrorPageSSO';
import MobileLogin from '../MobileLogin';
import Private from '../Private';
import ResetPassword from '../ResetPassword';
import SetNewPassword from '../SetNewPassword';
import SingleSignOn from '../SingleSignOn';
import SingleSignOut from '../SingleSignOut';
import UpdatePassword from '../UpdatePassword';
import AppErrorBoundary from './AppErrorBoundary';

import './index.scss';

const App = () => {
  return (
    <AppErrorBoundary>
      <BrowserLocationProvider>
        <CSSThemeProvider>
          <LegacyDiv className="height-100">
            <Router className="height-100" basepath={window.basePath}>
              <ResetPassword path="resetpassword" />
              <UpdatePassword path="updatepassword" />
              <UpdatePassword path="newpassword" />
              <MobileLogin path="mobilelogin" />
              <SingleSignOn path="sso/*" />
              <SingleSignOut path="ssosession/logout/*" />
              <Redirect from="login" to="/" noThrow />
              <Private path="/*" />
              <SetNewPassword
                path="/setnewpassword"
                pathToGoBack={window.basePath}
              />
              <ErrorPageSSO path="/ssoerror" pathToGoBack={window.basePath} />
            </Router>
          </LegacyDiv>
        </CSSThemeProvider>
      </BrowserLocationProvider>
    </AppErrorBoundary>
  );
};

export default App;
